<template>
  <div class="calendar-chip-container" :class="{ 'is-active': isModified }">
    <div class="chip-icon">
      <uds-icon
        class="icon"
        :class="{ 'is-active': isModified }"
        icon-name="calendar_today"
        size="small"
      />
    </div>
    <template v-if="shouldDisplayCenterContent">
      <div class="chip-center">
        <div class="chip-text" :class="{ 'is-active': isModified }">
          <span>{{ dateStartFormatted }}</span>
        </div>
        <div class="text-divider" :class="{ 'white-background-color': isModified }" />
        <div class="chip-text" :class="{ 'is-active': isModified }">
          <span>{{ dateEndFormatted }}</span>
        </div>
      </div>
    </template>
    <template v-if="isModified">
      <div class="chip-close">
        <uds-icon
          class="icon"
          icon-name="cancel"
          size="small"
          :class="{ 'is-active': isModified }"
          @click="$emit('reset')"
        />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
const { formatDate } = useDateFormat();

defineEmits(["reset"]);

const props = withDefaults(
  defineProps<{
    workableWidth: number;
    isModified: boolean;
    startDate: string | null;
    endDate: string | null;
    maxWidth?: number;
  }>(),
  {
    maxWidth: 1100,
  },
);

const dateStartFormatted = computed(() => {
  return formatDate(props.startDate ?? "", "DD/MM/YYYY");
});

const dateEndFormatted = computed(() => {
  return formatDate(props.endDate ?? "", "DD/MM/YYYY");
});

const isMaxRangeOfDate = computed<boolean>(() => {
  return props.endDate === "2170-12-31" && props.startDate === "1970-01-01";
});

const shouldDisplayCenterContent = computed<boolean>(() => {
  if (isMaxRangeOfDate.value || typeof props.maxWidth !== "number") {
    return false;
  }
  return props.workableWidth > props.maxWidth;
});
</script>

<style lang="scss" scoped>
.calendar-chip-container {
  background: $uds-neutral-200;
  border-radius: 17.5px;
  display: flex;
  align-items: center;
  height: 35px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &.is-active {
    background-color: $uds-google-blue;
  }

  .icon {
    cursor: pointer;
    color: $uds-neutral-800;
    &.is-active {
      color: $uds-white;
    }
  }

  .chip-icon,
  .chip-center,
  .chip-close {
    display: flex;
    align-items: center;
  }

  .chip-icon {
    margin: 0 10px;
  }

  .chip-center {
    margin: 0 15px 0 3px;

    .chip-text {
      font-size: 16px;
      line-height: 20px;
      font-weight: $uds-weight-450;
      color: $uds-neutral-800;
      min-width: 77px;

      &.is-active {
        font-weight: $uds-weight-500;
        margin-right: 7px;
        color: $uds-white;
      }
    }

    .text-divider {
      height: 35px;
      width: 1px;
      margin: 0 10px;
      background-color: $uds-neutral-500;

      &.white-background-color {
        background-color: $uds-white;
      }
    }
  }

  .chip-close {
    margin-right: 6px;
  }
}
</style>
